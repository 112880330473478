<template>
    <div>
        <WaveDownBG>
            <slot name="navigation" />
        </WaveDownBG>

        <div class="c w-s my-xxl pb-xxl">
            <div v-if="typeof article.config_en === 'string'" class="richtext color-secondary leading-m l-up:pr-xl accentlink" v-html="article.config_en" />
        </div>

        <WaveUpBG>
            <div class="c w-xxl overflow-hidden">
                <Logos class="my-xxl py-xxl" v-if="article && article.team" :title="$t('about.team')" :list="article.team">
                    <template v-slot:item="{ item }">
                        <div class="teammember">
                            <div v-if="item.image" class="teammember__img mb-l">
                                <ImageClip :image="cdnurl(item.image)" />
                            </div>
                            <div class="font-title weight-b">{{ item.name }}</div>
                        </div>
                    </template>
                </Logos>

                <Logos class="my-xxl py-xxl" v-if="article && article.board" :title="$t('about.board')" :list="article.board">
                    <template v-slot:item="{ item }">
                        <div class="teammember">
                            <div v-if="item.image" class="teammember__img mb-l">
                                <ImageClip :image="cdnurl(item.image)" />
                            </div>
                            <div class="font-title weight-b">{{ item.name }}</div>
                        </div>
                    </template>
                </Logos>
            </div>
        </WaveUpBG>
        <WaveDownBG />

        <div class="c w-xxl">
            <Logos class="my-xxl py-xxl" v-if="article && article.entrepreneurs" :title="$t('about.entrepreneurs')" :list="article.entrepreneurs">
                <template v-slot:item="{ item }">
                    <div class="teammember">
                        <div v-if="item.image" class="teammember__img mb-l">
                            <ImageClip :image="cdnurl(item.image)" />
                        </div>
                        <div class="font-title weight-b">{{ item.name }}</div>
                    </div>
                </template>
            </Logos>

            <Logos class="my-xxl py-xxl" v-if="data && data.foundation_partners" :title="$t('general.partners')" :list="data.foundation_partners" :needsImage="true" />
        </div>
    </div>
</template>

<script>
import WaveUpBG from '@/components/bgs/WaveUp.vue';
import WaveDownBG from '@/components/bgs/WaveDownSmall.vue';
import Logos from '@/components/ui/Logos.vue';
import ImageClip from '@/components/ImageClip.vue';
import { strings } from 'p5-helpers';

export default {
    name: 'Article',
    components: {
        WaveDownBG,
        WaveUpBG,
        Logos,
        ImageClip,
    },
    data() {
        return {
            article: {},
        };
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => vm.getData());
    },
    beforeRouteUpdate(to, from, next) {
        next();
        this.getData();
    },
    methods: {
        getData() {
            if (window.PRERENDER_INJECTED) {
                const routedata = window.PRERENDER_INJECTED.routeData[this.$route.path];
                if (routedata) {
                    this.processData(routedata);
                }
            } else {
                const url = strings.replaceBrackets(this.$route.meta.article.endpoint, this.$route.params);
                this.$store.dispatch('get', url).then(this.processData);
            }
        },

        processData(r) {
            this.article = r;

            let schemas = [];
            if (this.$route.meta.article.schema) {
                if (typeof this.$route.meta.article.schema === 'function') {
                    schemas = this.$route.meta.article.schema(r, this);
                } else {
                    schemas = this.$route.meta.article.schema;
                }
            }
            this.$store.commit('setMeta', {
                data: {
                    title: r.meta_title_en,
                    description: r.meta_description_en,
                    keywords: (Array.isArray(r.meta_keywords_en) ? r.meta_keywords_en : []).map((it) => it.name).join(', '),
                },
                schemas,
            });
        },
    },
    computed: {
        data() {
            return this.$store.getters.data;
        },
    },
};
</script>